/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import { useIntl, FormattedMessage } from 'gatsby-plugin-intl';
import CustomerCard from '../components/CustomerCard';
import LabelText from '../components/LabelText';
import Layout from '../components/layout/Layout';
import SplitSection from '../components/SplitSection';
import customerData from '../data/customer-data';
import customerEnData from '../data/customer-data.en';

const googleLogo = require('../assets/google.png');
const appleLogo = require('../assets/apple.png');

const heroImage = require('../assets/hero.png');
const styleImage = require('../assets/style.png');
const postsImage = require('../assets/posts.png');
const darkImage = require('../assets/dark.png');
const categoryImage = require('../assets/category.png');
const themesImage = require('../assets/themes.png');
const cloudImage = require('../assets/cloud.png');

const appstoreLink = 'https://apps.apple.com/app/id1509189573';
const googleplayLink = 'https://play.google.com/store/apps/details?id=com.pxmage.egoreader';
const chinaDownloadLink = 'https://www.yuque.com/pocv40/alcg2a/leryzq';
const customerDatas = {
  zh: customerData,
  en: customerEnData
};

export default () => {
  const intl = useIntl();

  return (
    <Layout>
      <section className="pt-20 md:pt-20 bg-gray-100 pb-10 sm:pb-20">
        <div className="container mx-auto px-8 lg:flex items-center">
          <div className="text-center lg:text-left lg:w-1/2">
            <h1 className="text-4xl lg:text-5xl xl:text-6xl font-bold leading-none">Ego Reader</h1>
            <p className="text-xl lg:text-2xl mt-6 font-light">
              <FormattedMessage id="slogan" />
            </p>
            <p className="mt-8 flex flex-row justify-center lg:justify-start ">
              <a href={appstoreLink} target="_blank">
                <img
                  src={appleLogo}
                  className="w-32 sm:w-40 h-12 sm:h-16 rounded-lg sm:rounded-xl"
                />
              </a>
              <a href={googleplayLink} target="_blank">
                <img
                  src={googleLogo}
                  className="w-32 sm:w-40 h-12 sm:h-16 rounded-lg sm:rounded-xl ml-6"
                />
              </a>
            </p>
            <p className="mt-4 text-gray-600">
              <FormattedMessage id="availability" />
              {intl.locale === 'zh' && (
                <span>
                  {'。 '}
                  国内用户安卓版<a href={chinaDownloadLink}>下载地址</a>
                </span>
              )}
            </p>
          </div>
          <div className="lg:w-1/2 mt-5 sm:mt-0">
            <img src={heroImage} className="transform sm:scale-125" />
            {/* <HeroImage /> */}
          </div>
        </div>
      </section>
      <section id="features" className="pt-20 lg:pb-0 lg:pt-28">
        <div className="container mx-auto text-center">
          <h2 className="text-3xl lg:text-5xl font-semibold">
            <FormattedMessage id="mainFeatures" />
          </h2>
          {/* <div className="flex flex-col sm:flex-row sm:-mx-3 mt-12">
          <div className="flex-1 px-3">
            <Card className="mb-8">
              <p className="font-semibold text-xl">Service One</p>
              <p className="mt-4">
                An enim nullam tempor gravida donec enim ipsum blandit porta justo integer odio
                velna vitae auctor integer.
              </p>
            </Card>
          </div>
          <div className="flex-1 px-3">
            <Card className="mb-8">
              <p className="font-semibold text-xl">Service Two</p>
              <p className="mt-4">
                An enim nullam tempor gravida donec enim ipsum blandit porta justo integer odio
                velna vitae auctor integer.
              </p>
            </Card>
          </div>
          <div className="flex-1 px-3">
            <Card className="mb-8">
              <p className="font-semibold text-xl">Service Three</p>
              <p className="mt-4">
                An enim nullam tempor gravida donec enim ipsum blandit porta justo integer odio
                velna vitae auctor integer.
              </p>
            </Card>
          </div>
        </div> */}
        </div>
      </section>
      <SplitSection
        id="features"
        reverseOrder
        primarySlot={
          <div className="lg:pl-32 xl:pl-48">
            <h3 className="text-2xl md:text-3xl font-semibold leading-tight">
              <FormattedMessage id="features.twoStyle" />
            </h3>
            <p className="mt-4 md:mt-8 text-xl font-light leading-relaxed">
              <FormattedMessage id="features.twoStyleDesc" />
            </p>
          </div>
        }
        secondarySlot={<img src={styleImage} className="transform scale-125" />}
      />
      <SplitSection
        primarySlot={
          <div className="lg:pr-32 xl:pr-48">
            <h3 className="text-2xl md:text-3xl font-semibold leading-tight">
              <FormattedMessage id="features.cardPost" />
            </h3>
            <p className="mt-4 md:mt-8 text-xl font-light leading-relaxed">
              <FormattedMessage id="features.cardPostDesc" />
            </p>
          </div>
        }
        secondarySlot={<img src={postsImage} className="transform scale-125" />}
      />
      <SplitSection
        reverseOrder
        primarySlot={
          <div className="lg:pl-32 xl:pl-48">
            <h3 className="text-2xl md:text-3xl font-semibold leading-tight">
              <FormattedMessage id="features.darkmode" />
            </h3>
            <p className="mt-4 md:mt-8 text-xl font-light leading-relaxed">
              <FormattedMessage id="features.darkmodeDesc" />
            </p>
          </div>
        }
        secondarySlot={<img src={darkImage} className="transform scale-125" />}
      />
      <SplitSection
        id="services"
        primarySlot={
          <div className="lg:pr-32 xl:pr-48">
            <h3 className="text-2xl md:text-3xl font-semibold leading-tight">
              <FormattedMessage id="features.cate" />
            </h3>
            <p className="mt-4 md:mt-8 text-xl font-light leading-relaxed">
              <FormattedMessage id="features.cateDesc" />
            </p>
          </div>
        }
        secondarySlot={<img src={categoryImage} className="transform scale-130" />}
      />
      <SplitSection
        reverseOrder
        primarySlot={
          <div className="lg:pl-32 xl:pl-48">
            <h3 className="text-2xl md:text-3xl font-semibold leading-tight">
              <FormattedMessage id="features.theme" />
            </h3>
            <p className="mt-4 md:mt-8 text-xl font-light leading-relaxed">
              <FormattedMessage id="features.themeDesc" />
            </p>
          </div>
        }
        secondarySlot={<img src={themesImage} className="transform scale-130 md:scale-150" />}
      />
      <SplitSection
        primarySlot={
          <div className="lg:pr-32 xl:pr-48">
            <h3 className="text-2xl md:text-3xl font-semibold leading-tight">
              <FormattedMessage id="features.crosspf" />
            </h3>
            <p className="mt-4 md:mt-8 text-xl font-light leading-relaxed">
              <FormattedMessage id="features.crosspfDesc" />
            </p>
          </div>
        }
        secondarySlot={<img src={cloudImage} className="transform scale-125" />}
      />
      {/* <section id="stats" className="py-20 lg:pt-32">
      <div className="container mx-auto text-center">
        <LabelText className="text-gray-600">Our customers get results</LabelText>
        <div className="flex flex-col sm:flex-row mt-8 lg:px-24">
          <div className="w-full sm:w-1/3">
            <StatsBox primaryText="+100%" secondaryText="Stats Information" />
          </div>
          <div className="w-full sm:w-1/3">
            <StatsBox primaryText="+100%" secondaryText="Stats Information" />
          </div>
          <div className="w-full sm:w-1/3">
            <StatsBox primaryText="+100%" secondaryText="Stats Information" />
          </div>
        </div>
      </div>
    </section> */}
      <section id="testimonials" className="py-12 md:py-20">
        <div className="container mx-auto">
          <LabelText className="mb-8 text-gray-600 text-center">
            <FormattedMessage id="whatArePeopleSaying" />
          </LabelText>
          <div className="flex flex-col md:flex-row md:-mx-3">
            {customerDatas[intl.locale].map(customer => (
              <div key={customer.customerName} className="flex-1 px-3">
                <CustomerCard customer={customer} />
              </div>
            ))}
          </div>
        </div>
      </section>
      {/* <section className="container mx-auto my-20 py-24 bg-gray-200 rounded-lg text-center">
      <h3 className="text-5xl font-semibold">Ready to grow your business?</h3>
      <p className="mt-8 text-xl font-light">
        Quis lectus nulla at volutpat diam ut. Enim lobortis scelerisque fermentum dui faucibus in.
      </p>
      <p className="mt-8">
        <Button size="xl">Get Started Now</Button>
      </p>
    </section> */}
    </Layout>
  );
};
