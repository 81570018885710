const zetaAvatar = require('../assets/avatars/zeta.jpg')
const rajAvatar = require('../assets/avatars/raj.jpg')
const scottAvatar = require('../assets/avatars/scott.jpg')

export default [
  {
    title: ' It opens the door to a new world.',
    content:
      'As a heavy RSS user, when I first met Ego Reader, it was like a friend threw me a beautiful magazine while I am holding a black-and-white newspaper, my whole world instantly became colorful.',
    customerName: '@|Scott|',
    customerTitle: 'Ego Reader deep user',
    customerImage: scottAvatar
  },
  {
    title: "It's my ideal RSS reader!",
    content:
      'It’s just so good-looking, I can’t refuse a beautifully designed app, and this card style has won my heart. It’s just what RSS should be in my ideals.',
    customerName: '@Zeta',
    customerTitle: 'Commented in the chat group @Jun 20, 2020',
    customerImage: zetaAvatar
  },
  {
    title: 'Clean and modern.',
    content:
      'Great app!!, loved the UI its clean and modern.For days I have been searching for a perfect rss reader and as soon as I saw this app it got my attention, I bought the premium version. Hope the developer keep up his work and I am sure it will become one of the best rss reader.👍',
    customerName: '@Pri****aj N***an',
    customerTitle: 'Commented on Google Play @Oct 16, 2020',
    customerImage: rajAvatar
  },
];
