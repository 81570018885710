const scottAvatar = require('../assets/avatars/scott.jpg')
const lichaAvatar = require('../assets/avatars/licha.jpg')
const zetaAvatar = require('../assets/avatars/zeta.jpg')

export default [
  {
    title: '打开了新世界大门',
    content:
      '作为一个重度RSS用户，初遇Ego Reader时，就好像朋友在手捧黑白报纸的你面前扔了一本精美的杂志一般，瞬间连里面的内容也都有了光彩。',
    customerName: '@|Scott|',
    customerTitle: 'Ego Reader 深度用户',
    customerImage: scottAvatar
  },
  {
    title: '目前用过国内最好的RSS阅读器',
    content:'最近根据各种推荐下载了一批RSS阅读器试用，最后发现这一款体验感最好！界面整洁，功能清晰，小小白也能轻松上手。',
    customerName: '@李猹',
    customerTitle: '2020-10-25 评论于App Store',
    customerImage: lichaAvatar
  },
  {
    title: '是我理想中的RSS阅读器',
    content:
      '主要是颜值太高了，无法拒绝高颜值的App，而且这种卡片式深得我心，简直就是我理想中RSS该有的样子。',
    customerName: '@Zeta',
    customerTitle: '2020-06-20 评论于用户交流群',
    customerImage: zetaAvatar
  }
];
