import { FormattedMessage, useIntl } from 'gatsby-plugin-intl';
import React from 'react';

const Footer = () => {
  const intl = useIntl();

  return (
    <footer className="container mx-auto py-16 px-3 mt-28 md:mt-48 mb-8 text-gray-800">
      <div className="flex -mx-3 justify-around">
        <div className="flex-1 px-3">
          <h2 className="text-lg font-semibold">
            <FormattedMessage id="footer.aboutme" />
          </h2>
          <p className="mt-5">
            <FormattedMessage id="footer.aboutmeDesc" />
          </p>
        </div>
        <div className="flex-1 px-3">
          <h2 className="text-lg font-semibold">
            <FormattedMessage id="footer.myLinks" />
          </h2>
          <ul className="mt-4 leading-loose">
            <li>
              <a target="_blank" href="https://pxmage.com">
                <FormattedMessage id="footer.blog" />
              </a>
            </li>
          </ul>
        </div>
        <div className="flex-1 px-3">
          <h2 className="text-lg font-semibold">
            <FormattedMessage id="footer.friendLinks" />
          </h2>
          <ul className="mt-4 leading-loose">
            <li>
              <a target="_blank" href="https://github.com/Cay-Zhang/RSSBud">
                RSSBud
              </a>
            </li>
            <li>
              <a target="_blank" href="https://zenflipclock.cn/">
                <FormattedMessage id="footer.jijian" />
              </a>
            </li>
            <li>
              <a target="_blank" href="https://w2solo.com/">
                W2Solo
              </a>
            </li>
          </ul>
        </div>
      </div>
      {intl.locale === 'zh' && (
        <a
          style={{ color: '#a5a5a5', marginTop: '1rem', display: 'block', fontSize: '14px' }}
          href="https://beian.miit.gov.cn/"
          target="_blank"
        >
          浙ICP备16041826号-4
        </a>
      )}
    </footer>
  );
};

export default Footer;
