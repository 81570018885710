import React from 'react';

const SplitSection = ({ id, primarySlot, secondarySlot, reverseOrder }) => (
  <section id={id} className="py-8 md:py-32">
    <div className="container mx-auto px-16 items-center flex flex-col lg:flex-row">
      <div className="lg:w-1/2 mt-10 lg:mt-0">{primarySlot}</div>
      <div
        className={`mt-10 lg:mt-0 w-full lg:w-1/2 order-first ${reverseOrder? `lg:order-first ` : `lg:order-last`}`}
      >
        {secondarySlot}
      </div>
    </div>
  </section>
);

export default SplitSection;
