import { changeLocale, FormattedMessage, useIntl } from 'gatsby-plugin-intl';
import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import LogoIcon from '../../svg/LogoIcon';
import Button from '../Button';
const logo = require('../../assets/logo.png')

const documentsLink = {
  'zh': 'https://www.yuque.com/pocv40/alcg2a/rwn2w8',
  'en': 'https://www.yuque.com/pocv40/alcg2a/ap92w4'
}
const feedbackLinks = {
  'zh': 'https://www.yuque.com/pocv40/alcg2a/npmi2p',
  'en': 'https://www.yuque.com/pocv40/alcg2a/xpub7b'
}
const updatesLinks = {
  'zh': 'https://www.yuque.com/pocv40/alcg2a/dkqczq',
  'en': 'https://www.yuque.com/pocv40/alcg2a/xgzrk8'
}

const Header = () => {
  const intl = useIntl()

  return <header className="sticky top-0 bg-white shadow-sm z-10">
    <div className="container flex flex-row justify-between items-center mx-auto py-2 px-4 md:py-4 md:px-8 overflow-x-scroll sm:overflow-hidden">
      <div className="flex items-center text-2xl">
        <div className="w-12 mr-3">
          <img src={logo}/>
        </div>
        <div className="w-32 sm:text-xxl hidden sm:block">
          Ego Reader 
        </div>
      </div>
      <div className="flex mt-0 md:text-lg">
        <AnchorLink className="px-4 whitespace-no-wrap" href="#features">
          <FormattedMessage id="nav.features" />
        </AnchorLink>
        <AnchorLink className="px-4 whitespace-no-wrap" href="#testimonials">
          <FormattedMessage id="nav.testimonials" />
        </AnchorLink>
        <a className="px-4 whitespace-no-wrap" href={documentsLink[intl.locale]} target="_blank">
          <FormattedMessage id="nav.documents" />
        </a>
        <a className="px-4 whitespace-no-wrap" href={feedbackLinks[intl.locale]} target="_blank">
          <FormattedMessage id="nav.feedbacks" />
        </a>
        <a className="px-4 whitespace-no-wrap" href={updatesLinks[intl.locale]} target="_blank">
          <FormattedMessage id="nav.updates" />
        </a>
        <div className="px-4 whitespace-no-wrap">
          <span className="cursor-pointer text-primary" onClick={() => changeLocale('zh')}>中</span>
          {" / "}
          <span className="cursor-pointer text-primary" onClick={() => changeLocale('en')}>En</span>
        </div>
        
      </div>
      {/* <div className="hidden md:block">
        <Button className="text-sm">Start Free Trial</Button>
      </div> */}
    </div>
  </header>
};

export default Header;
