import React from 'react';
import { Helmet } from 'react-helmet';
import { FormattedMessage, useIntl } from 'gatsby-plugin-intl';
import Footer from './Footer';
import Header from './Header';

const logo = require('../../assets/logo.png');

const Layout = ({ children }) => {
  const intl = useIntl();
  return (
    <>
      <Helmet
        meta={[
          {
            name: 'keywords',
            content: intl.formatMessage({ id: 'keywords' })
          },
          {
            name: `description`,
            content: intl.formatMessage({ id: 'description' })
          }
        ]}
      >
        <title>{intl.formatMessage({ id: 'title' })}</title>
        <link rel="icon" href={logo} />
      </Helmet>
      <Header />
      <main className="text-gray-900">{children}</main>
      <Footer />
    </>
  );
};

export default Layout;
